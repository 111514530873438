/* SPORTSBOOK */
.team-icon-away {
  position: absolute;
  top: -6px;
  right: -61px;
}

.team-icon-away img,
.team-icon-home img {
  display: block;
  width: 85px;
  opacity: 0.8;
}

.team-icon-home {
  position: absolute;
  top: -6px;
  left: -61px;
}

.tv-statistic {
  position: relative;
  top: 226px;
  left: 254px;
  overflow: hidden;
  width: 266px;
  height: 114px;
  margin: 0 auto;
  text-shadow: none;
}

.tv-statistic.table-tennis {
  top: 155px;
  left: 251px;
}

.tv-statistic-slide {
  position: absolute;
  top: 0;
  left: -266px;
  visibility: hidden;
  width: 266px;
  height: 114px;
  padding: 8px 0 0 0;
  transition: .5s ease-in-out;
}

.tv-statistic-slide.active {
  top: 0;
  left: 0;
  visibility: visible;
}

.tv-statistic-slide.active + .tv-statistic-slide,
.tv-statistic.last-is-active .tv-statistic-slide:first-child {
  top: 0;
  left: 266px;
}

.tv-statistic-slide-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  padding: 13px 0 0 0;
  text-align: center;
  text-transform: uppercase;
  color: #898989;
}

.tv-statistic-block {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #898989;
}

.tv-statistic-block:nth-child(2) {
  font-size: 10px;
  margin-top: 2px;
}

.tv-statistic-block-title {
  white-space: nowrap;
  text-transform: uppercase;
}

.tv-statistic-block-content {
  font-size: 11px;
  position: relative;
  padding: 3px 10px 0 10px;
}

.left-val {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 10px;
  margin: 0 0 0 -26px;
}

.right-val {
  position: absolute;
  top: 10px;
  right: 50%;
  width: 10px;
  margin: 0 -26px 0 0;
}

.tv-statistic-block .c100 {
  font-size: 25px;
  float: none;
  margin: 0 auto;
  background-color: #b8b000;
}

.table-tennis .tv-statistic-block .c100 {
  background-color: #ffb400;
}

.tv-statistic-block .c100 .bar {
  border-color: #004ca3;
}

.table-tennis .tv-statistic-block .c100 .bar {
  border-color: #01bdff;
}

.tv-statistic-block .c100 .fill {
  border-color: #004ca3 !important;
}

.table-tennis .tv-statistic-block .c100 .fill {
  border-color: #01bdff !important;
}

.tv-statistic-block .c100:after {
  background-color: #373636;
}

.tv-statistic-bars {
  position: relative;
}

.tv-statistic-home-bar {
  float: left;
  height: 4px;
  background: #b8b000;
}

.table-tennis .tv-statistic-home-bar {
  background: #ffb400;
}

.tv-statistic-away-bar {
  float: left;
  height: 4px;
  background: #004ca3;
}

.table-tennis .tv-statistic-away-bar {
  background: #01bdff;
}

.tv-statistic-bars-block {
  margin-top: 8px;
  font-size: 10px;
}

.tv-statistic-bars-block .tv-statistic-block-title {
  padding: 0 12px 0 18px;
}

.tv-statistic-bars-block + .tv-statistic-bars-block .tv-statistic-block-title {
  padding: 0 18px 0 12px;
}

.tv-statistic-bars-block .tv-statistic-block-content {
  padding: 4px 16px 0 22px;
  font-size: 10px;
}

.tv-statistic-bars-block + .tv-statistic-bars-block .tv-statistic-block-content {
  padding: 4px 22px 0 16px;
}

.tv-statistic-bars-block .left-val {
  top: 0px;
  left: 11px;
  margin: 0;
}

.tv-statistic-bars-block .right-val {
  top: 0px;
  right: 4px;
  margin: 0;
}

.tv-statistic-bars-block + .tv-statistic-bars-block .left-val {
  left: 4px;
}

.tv-statistic-bars-block + .tv-statistic-bars-block .right-val {
  right: 11px;
}

.scoreboard-widget {
  position: relative;
  top: 352px;
  left: 156px;
  width: 482px;
  height: 92px;
  margin: 0 auto;
}

.svg_container {
  position: relative;
  top: 160px;
  left: 18px;
  overflow: hidden;
  width: 182px;
  height: 90px;
  margin: 0 auto;
  cursor: default;
  text-shadow: none;
}

.svg_container * {
  box-sizing: border-box;
}

.sr-widgets-momentum2 .sr-content {
  width: 100%;
  height: 100%;
}

.sr-widgets-momentum2 .sr-chartist {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  cursor: default;
}

.sr-widgets-momentum2 .sr-chartist svg {
  position: relative;
  z-index: 2;
}

.sr-chartist .sr-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-dasharray: 2px;
  stroke-width: 1px;
}

.sr-chartist .sr-line {
  fill: none;
  stroke-width: 4px;
}

.sr-chartist .sr-label {
  font-size: .75rem;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
  fill: rgba(0, 0, 0, 0.4);
}

.sr-widgets-momentum2 .sr-grid {
  stroke: #5f5f5f;
  stroke-dasharray: 3px;
}

.sr-widgets-momentum2 .sr-grid-first,
.sr-widgets-momentum2 .sr-vertical {
  stroke-dasharray: 0;
}

.sr-widget.sr-widgets-momentum2 .sr-stroke-home {
  stroke: #ffb400;
}

.sr-widgets-momentum2 .sr-line {
  stroke-width: 1px;
}

.sr-widget.sr-widgets-momentum2 .sr-stroke-away {
  stroke: #01bdff;
}

.sr-chartist .sr-chart-line .sr-label,
.sr-chartist .sr-chart-bar .sr-label {
  display: block;
  display: flex;
}

.sr-widgets-momentum2 .sr-label,
.sr-widgets-momentum2 .sr-indicator-label {
  font-size: 11px;
}

.sr-widgets-momentum2 .sr-event-text,
.sr-widgets-momentum2 .sr-label,
.sr-widgets-momentum2 .sr-indicator-label {
  text-anchor: middle;
}

.sr-widgets-momentum2 .sr-label {
  fill: #5f5f5f;
}

.sr-widget.sr-widgets-momentum2 .sr-fill-home {
  fill: #ffb400;
}

.sr-widgets-momentum2 .sr-event-circle {
  stroke: #f5f5f5;
  stroke-width: .3;
}

.sr-widget .sr-fill-home {
  fill: #ffb400;
}

.sr-widget.sr-widgets-momentum2 .sr-fill-away {
  fill: #01bdff;
}

.sr-widgets-momentum2 .sr-event-circle {
  stroke: #f5f5f5;
  stroke-width: .3;
}

.sr-widget .sr-fill-away {
  fill: #01bdff;
}

.sr-widget.sr-widgets-momentum2 .sr-event-text {
  fill: #fff;
}

.sr-widgets-momentum2 .sr-event-text-onbg {
  fill: #fff !important;
}

.sr-widgets-momentum2 .sr-event-text,
.sr-widgets-momentum2 .sr-label,
.sr-widgets-momentum2 .sr-indicator-label {
  text-anchor: middle;
}

.sr-widgets-momentum2 .sr-event-text {
  font-size: 10px;
}

.sr-widgets-momentum2 .sr-team-top-bg,
.sr-widgets-momentum2 .sr-team-bottom-bg {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 17px;
  border: 1px solid #1e1f20;
  background: rgba(255, 255, 255, 0.1);
}

.sr-widgets-momentum2 .sr-team-bottom-bg {
  bottom: 14px;
}

.sr-widgets-momentum2 .sr-team {
  position: absolute;
  z-index: 3;
  overflow: hidden;
  height: 15px;
}

.sr-widgets-momentum2 .sr-team-top {
  top: 1px;
  left: 1px;
}

.sr-widgets-momentum2 .sr-team-bottom {
  bottom: 15px;
  left: 1px;
}

.sr-widgets-momentum2 .sr-team-marker {
  position: absolute;
  top: 0;
  left: 0;
}

.sr-widgets-momentum2 .sr-team-title {
  font-size: 10px;
  line-height: 15px;
  float: left;
}

.sr-widgets-momentum2 .sr-double {
  margin-left: 8px;
}

.sr-widgets-momentum2 .sr-team-bottom {
  bottom: 15px;
}

.sr-widgets-momentum2 .sr-set-selector {
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 14px;
  left: 0;
  width: 50px;
  height: 19px;
  margin: auto;
}

.sr-widget .sr-header-1 {
  /*background-color: #0072b1;*/
  color: #fff;
}

.sr-widgets-momentum2 .sr-set-list {
  font-size: 10px;
  padding: 3px 13px 3px 6px;
  border-radius: 2px;
}

.sr-ball-spot {
  position: absolute;
  width: 1px;
  height: 1px;
}

.up .sr-ball-spot .sr-position-shadow {
  transition-timing-function: ease-out;
  transform: scale(1, 0.5);
}

.sr-ball-spot .sr-position-shadow {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  width: 8px;
  height: 8px;
  margin: auto;
  transition: transform 750ms;
  transition-timing-function: ease-in;
  transform: scale(0.2, 0.1);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  will-change: transform;
}

.sr-ball-spot .sr-position-circle {
  position: absolute;
  top: -50px;
  right: -50px;
  bottom: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  margin: auto;
  transform: scale(0.4, 0.2);
}

.sr-ball-spot .sr-position-circle-inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0);
  border-width: 50px;
  border-style: solid;
  border-radius: 50%;
  will-change: transform, border-width, opacity;
}

.up .sr-ball-spot .sr-position-circle-inner {
  transition: transform 750ms, border-width 592.5ms 157.5ms, opacity 225ms 525ms;
  transform: scale(1);
  opacity: 0;
  border-width: 0 !important;
}

.home .sr-ball-spot .sr-position-circle-inner {
  border-color: #9b000a;
  box-shadow: 0 0 3px 1px #9b000a, 0 0 3px 1px #9b000a inset;
}

.away .sr-ball-spot .sr-position-circle-inner {
  border-color: #0550a5;
  box-shadow: 0 0 3px 1px #0550a5, 0 0 3px 1px #0550a5 inset;
}

.sr-ball-spot .sr-lmts-icon-man {
  font-size: 36px;
  position: absolute;
  right: -20px;
  bottom: 2px;
  left: -20px;
  transition: transform 750ms;
  transition-timing-function: ease-in;
  transform-origin: 50% 100%;
  text-align: center;
  will-change: transform;
}

.sr-lmts-icon-man:after {
  position: absolute;
  z-index: -1;
  bottom: 24%;
  left: 14%;
  width: 75%;
  height: 75%;
  content: "";
  border-radius: 50%;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);
}

.rotate1 .sr-ball-spot .sr-lmts-icon-man {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: translateY(0) scale(0.01, 1);
}

.rotate2 .sr-ball-spot .sr-lmts-icon-man {
  transition-timing-function: linear;
  transition-duration: 200ms;
}

.up .sr-ball-spot .sr-lmts-icon-man {
  transition-timing-function: ease-out;
  transform: translateY(-20%) scale(1);
}

.hide .sr-ball-spot .sr-lmts-icon-man {
  transition-duration: 300ms;
  transform: translateY(0) scale(0.01);
}

.show .sr-ball-spot .sr-lmts-icon-man {
  transition-timing-function: ease-out;
  transition-duration: 300ms;
}

.home .sr-lmts-icon-man {
  color: #9b000a;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.away .sr-lmts-icon-man {
  color: #0550a5;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.sr-lmts-icon-man .sr-lmts-icon-man-sport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  text-shadow: none;
}

.sr-ball-spot .sr-inline-event {
  font-size: 9px;
  font-weight: 500;
  position: absolute;
  top: -55px;
  padding: 0 3px;
  transition: opacity, 300ms;
  transform: translateX(-50%);
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0;
  color: #000;
  border: 1px solid #000;
  border-radius: 2px;
  background: #fff;
}

.sr-ball-behind {
  position: absolute;
  width: 14px;
  height: 10px;
  margin-top: -7px;
  margin-left: -27px;
  transition: opacity 300ms;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.01);
  will-change: opacity;
}

.hide .sr-ball-behind,
.first-step .sr-ball-behind {
  opacity: 0;
}

.away .sr-ball-behind,
.home.side-changed .sr-ball-behind {
  box-shadow: 20px 0 5px 0 rgba(5, 80, 165, 0.5);
}

.home .sr-ball-behind,
.away.side-changed .sr-ball-behind {
  box-shadow: 20px 0 5px 0 rgba(155, 0, 10, 0.5);
}

.sr-ball-line {
  position: absolute;
  visibility: hidden;
  height: 4px;
  transform-origin: 0 50%;
}

.show .sr-ball-line,
.hide .sr-ball-line {
  visibility: inherit;
}

.sr-ball-line .sr-ball-line-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: radial-gradient(farthest-side at 0 50%, #f3ee57, rgba(243, 238, 87, 0.1));
  will-change: transform;
}

.hide .sr-ball-line-inner,
.show .sr-ball-line-inner {
  animation: sr-lmts-possession-line 600ms linear forwards;
}

@keyframes sr-lmts-possession-line {
  0% {
    transform: scaleX(0);
    transform-origin: 100% 50% 0;
  }
  50% {
    transform: scaleX(1);
    transform-origin: 100% 50% 0;
  }
  50.1% {
    transform-origin: 0 50% 0;
  }
  100% {
    transform: scaleX(0);
    transform-origin: 0 50% 0;
  }
}

.sr-lmts-positioned-event-spot {
  position: absolute;
}

.sr-lmts-positioned-event-spot .sr-event-spot {
  position: relative;
  width: 20px;
  height: 8px;
  margin-top: -4px;
  margin-left: -10px;
}

.sr-lmts-positioned-event-spot .sr-lmts-icon-man {
  font-size: 36px;
  position: absolute;
  z-index: 22;
  bottom: 9px;
  left: 50%;
  visibility: hidden;
  transform: translateX(-50%);
}

.sr-lmts-positioned-event-spot .sr-circle-first {
  position: absolute;
  z-index: 21;
  visibility: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
}

.sr-lmts-positioned-event-spot .sr-circle {
  position: absolute;
  z-index: 19;
  visibility: hidden;
  transform: scale(0.2);
  border-radius: 50%;
}

.sr-lmts-positioned-event-spot .sr-circle.sr-1 {
  top: -35%;
  left: -50%;
  width: 200%;
  height: 200%;
  opacity: .7;
}

.sr-lmts-positioned-event-spot .sr-circle.sr-2 {
  top: -70%;
  left: -100%;
  width: 300%;
  height: 300%;
  opacity: .4;
}

.sr-lmts-positioned-event-spot .sr-circle.sr-3 {
  top: -120%;
  left: -150%;
  width: 400%;
  height: 400%;
  opacity: .3;
}

.sr-lmts-positioned-event-spot .sr-spot-fadein .sr-lmts-icon-man,
.sr-lmts-positioned-event-spot .sr-spot-fadein .sr-circle-first {
  visibility: inherit;
}

.sr-lmts-positioned-event-spot .sr-spot-fadein .sr-circle {
  visibility: inherit;
  transition: all 500ms;
  transform: scale(1);
}

.sr-lmts-positioned-event-spot .sr-spot-fadeout .sr-circle-first,
.sr-lmts-positioned-event-spot .sr-spot-fadeout .sr-circle {
  visibility: hidden;
  transition: all 500ms linear;
  transform: scale(0.2);
}

.sr-lmts-positioned-event-spot.away .sr-circle {
  background-color: #0550a5;
}

.sr-lmts-positioned-event-spot.home .sr-circle {
  background-color: #9b000a;
}

.pitch-status {
  position: relative;
  width: 100%;
  height: 100%;
}

.away-pitch-status {
  position: absolute;
  top: 3px;
  right: 29px;
  display: none;
  width: 175px;
  height: 74px;
  transition: .5s linear;
}

.home-pitch-status {
  position: absolute;
  top: 3px;
  left: 28px;
  display: none;
  width: 180px;
  height: 74px;
  transition: .5s linear;
}

.away-pitch-status .pitch-status-el1 {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  border-right: 68px solid transparent;
  border-bottom: 74px solid rgba(0, 0, 0, 0.2);
  border-left: 0 solid transparent;
}

.home-pitch-status .pitch-status-el1 {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  border-right: 0 solid transparent;
  border-bottom: 74px solid rgba(0, 0, 0, 0.2);
  border-left: 75px solid transparent;
}

.away-pitch-status .pitch-status-el2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0 0 0 -30px;
  border-top: 34px solid transparent;
  border-right: 30px solid rgba(0, 0, 0, 0.2);
  border-bottom: 41px solid transparent;
}

.home-pitch-status .pitch-status-el2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: 0 -30px 0 0;
  border-top: 27px solid transparent;
  border-bottom: 48px solid transparent;
  border-left: 30px solid rgba(0, 0, 0, 0.2);
}

.away-team-status {
  position: absolute;
  top: -6px;
  right: 97px;
  display: none;
  box-sizing: border-box;
  width: 107px;
  height: 0;
  transition: .5s linear;
  border-right: 0 solid transparent;
  border-bottom: 8px solid rgba(5, 80, 165, 0.7);
  border-left: 8px solid transparent;
}

.home-team-status {
  position: absolute;
  top: -6px;
  left: 102px;
  display: none;
  box-sizing: border-box;
  width: 106px;
  height: 0;
  transition: .5s linear;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(155, 0, 10, 0.7);
  border-left: 0 solid transparent;
}

.away .away-team-status,
.away .away-pitch-status,
.home .home-team-status,
.home .home-pitch-status {
  display: block;
}

.away-team-status img {
  position: absolute;
  top: -22px;
  right: 0;
  display: block;
  width: 32px;
  opacity: 0.8;
}

.home-team-status img {
  position: absolute;
  top: -22px;
  left: 0;
  display: block;
  width: 32px;
  opacity: 0.8;
}

.away-team-status .away-team-name {
  font-size: 10px;
  font-weight: 200;
  position: absolute;
  top: -18px;
  right: 32px;
  text-transform: uppercase;
  color: #c6c5c5;
}

.away-team-status .away-team-name-status {
  font-size: 12px;
  font-weight: 200;
  position: absolute;
  top: -8px;
  right: 32px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #c6c5c5;
}

.home-team-status .home-team-name {
  font-size: 10px;
  font-weight: 200;
  position: absolute;
  top: -18px;
  left: 32px;
  text-transform: uppercase;
  color: #c6c5c5;
}

.home-team-status .home-team-name-status {
  font-size: 12px;
  font-weight: 200;
  position: absolute;
  top: -8px;
  left: 32px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #c6c5c5;
}

.sr-positioned-ticker {
  position: absolute;
  z-index: 25;
  top: 40px;
  left: 100px;
  text-shadow: none;
}

.sr-positioned-ticker.sr-ticker-hidden {
  opacity: 0;
}

.sr-positioned-ticker.sr-ticker-fadeout {
  animation: sr-lmts-fadeout forwards 500ms;
}

.sr-positioned-ticker.sr-ticker-slidein {
  animation: sr-lmts-slidein-top 500ms;
}

.sr-positioned-ticker * {
  box-sizing: border-box;
}

.srw-lmts-pitch-ticker {
  color: #000;
  border-radius: 2px;
  background: #f5f5f5;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.6);
}

.sr-generic-ticker {
  position: relative;
  height: 40px;
  transition: background-color 200ms linear;
}

.sr-ticker-content-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 7px;
  border-collapse: separate;
  vertical-align: middle;
}

.sr-ticker-tooltip-text {
  display: table-cell;
  overflow: hidden;
  min-width: 60px;
  text-align: left;
  vertical-align: inherit;
  text-transform: uppercase;
  border-right: 8px solid transparent;
}

.sr-ticker-tooltip-text .sr-ticker-text-1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sr-ticker-tooltip-text .sr-ticker-text-2 {
  font-size: 12px;
  line-height: 14px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .6;
}

.sr-positioned-ticker .sr-ticker-tooltip-text {
  min-width: 65px;
  max-width: 100px;
}

.sr-ticker-side {
  font-size: 16px;
  line-height: 16px;
  float: right;
  overflow: hidden;
  box-sizing: content-box;
  min-width: 20px;
  height: 16px;
  padding: 7px 3px 7px 5px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  background-color: #0550a5;
}

.away .sr-ticker-side {
  background-color: #0550a5;
}

.home .sr-ticker-side {
  background-color: #9b000a;
}

.sr-ticker-fadein.sr-ticker-child .sr-time-wrap,
.sr-ticker-slidein .sr-time-wrap,
.sr-ticker-fadeout .sr-time-wrap {
  animation: sr-lmts-heatmap-fadein .3s 0s forwards;
  opacity: 1;
}

.sr-ticker-side .sr-time-wrap {
  display: block;
  overflow: hidden;
}

.sr-ticker-side .sr-time-wrap-element {
  height: 30px;
  white-space: nowrap;
}

.sr-ticker-fadein.sr-ticker-child .sr-time-wrap-element,
.sr-ticker-slidein .sr-time-wrap-element,
.sr-ticker-fadeout .sr-time-wrap-element {
  transform: translateY(-30px);
  animation: sr-lmts-heatmap-timeslide 1s .1s forwards;
}

@keyframes sr-lmts-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sr-lmts-heatmap-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sr-lmts-heatmap-timeslide {
  0% {
    transform: translateY(-150px);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@font-face {
  font-family: LMTS-icons;
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/LMT/LMT.eot");
  src: url("../fonts/LMT/LMT.eot?#iefix") format("embedded-opentype"), url("../fonts/LMT/LMT.woff") format("woff"), url("../fonts/LMT/LMT.ttf") format("truetype"), url("../fonts/LMT.svg#batchregular") format("svg");
}

.sr-lmts-icon {
  font-family: "LMTS-icons" !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
}

.sr-lmts-icon-sport-1:before {
  content: "\F021";
}

.sr-lmts-icon-sport-2:before {
  content: "\F008";
}

.sr-lmts-icon-sport-5:before {
  content: "\F013";
}

.sr-lmts-icon-sport-12:before {
  content: "\F052";
}

.sr-lmts-icon-sport-34:before {
  content: "\F014";
}

.sr-lmts-icon-sport-23:before {
  content: "\F015";
}

.sr-lmts-icon-sport-22:before {
  content: "\F115";
}

.sr-lmts-icon-sport-31:before {
  content: "\F016";
}

.sr-lmts-icon-sport-31.sr-ball-indicator:before {
  content: "\F017";
}

.sr-lmts-icon-sport-20:before {
  content: "\F019";
}

.sr-lmts-icon-sport-20.sr-ball-indicator:before {
  content: "\F020";
}

.sr-lmts-icon-info:before {
  content: "\F024";
}

.sr-lmts-icon-live:before {
  content: "\F063";
}

.sr-lmts-icon-replay:before {
  content: "\F023";
}

.sr-lmts-icon-heatmap:before {
  content: "\F077";
}

.sr-lmts-icon-hamburger:before {
  content: "\F062";
}

.sr-lmts-icon-lineups:before {
  content: "\F107";
}

.sr-lmts-icon-collapse-up:before {
  content: "\F006";
}

.sr-lmts-icon-collapse-down:before {
  content: "\F005";
}

.sr-lmts-icon-replay-play:before {
  content: "\F058";
}

.sr-lmts-icon-replay-pause:before {
  content: "\F059";
}

.sr-lmts-icon-replay-stop:before {
  content: "\F057";
}

.sr-lmts-icon-replay-backward:before {
  content: "\F061";
}

.sr-lmts-icon-replay-forward:before {
  content: "\F060";
}

.sr-lmts-icon-scored2pt:before {
  content: "\F048";
}

.sr-lmts-icon-scored3pt:before {
  content: "\F047";
}

.sr-lmts-icon-missed2pt:before {
  content: "\F049";
}

.sr-lmts-icon-missed3pt:before {
  content: "\F050";
}

.sr-lmts-icon-handball-goal:before {
  content: "\F080";
}

.sr-lmts-icon-handball-safe:before {
  content: "\F079";
}

.sr-lmts-icon-handball-fouls:before {
  content: "\F078";
}

.sr-lmts-icon-heatmap-goals:before {
  content: "\F041";
}

.sr-lmts-icon-heatmap-ontarget:before {
  content: "\F038";
}

.sr-lmts-icon-heatmap-offtarget:before {
  content: "\F039";
}

.sr-lmts-icon-heatmap-freekicks:before {
  content: "\F042";
}

.sr-lmts-icon-heatmap-offsides:before {
  content: "\F040";
}

.sr-lmts-icon-rugby-try:before {
  content: "\F053";
}

.sr-lmts-icon-rugby-conversion:before {
  content: "\F056";
}

.sr-lmts-icon-rugby-penaltygoal:before {
  content: "\F054";
}

.sr-lmts-icon-rugby-dropgoal:before {
  content: "\F055";
}

.sr-lmts-icon-lineup-tactical:before {
  content: "\F108";
}

.sr-lmts-icon-lineup-home:before {
  content: "\F109";
}

.sr-lmts-icon-lineup-away:before {
  content: "\F111";
}

.sr-lmts-icon-lineup-subhome:before {
  content: "\F110";
}

.sr-lmts-icon-lineup-subaway:before {
  content: "\F112";
}

.sr-lmts-icon-lineup-left:before {
  content: "\F009";
}

.sr-lmts-icon-lineup-right:before {
  content: "\F010";
}

.sr-lmts-icon-venue:before {
  content: "\F066";
}

.sr-lmts-icon-capacity:before,
.sr-lmts-icon-competitors:before {
  content: "\F075";
}

.sr-lmts-icon-pitch-soccer:before {
  content: "\F073";
}

.sr-lmts-icon-pitch-rugby:before {
  content: "\F068";
}

.sr-lmts-icon-weather:before {
  content: "\F069";
}

.sr-lmts-icon-referee:before {
  content: "\F065";
}

.sr-lmts-icon-bestof:before {
  content: "\F071";
}

.sr-lmts-icon-lastwinner:before {
  content: "\F072";
}

.sr-lmts-icon-prize:before {
  content: "\F074";
}

.sr-lmts-icon-temperature:before {
  content: "\F070";
}

.sr-lmts-icon-wind:before {
  content: "\F067";
}

.sr-lmts-icon-windadv:before {
  content: "\F076";
}

.sr-icon-sport-1:before {
  content: "\F021";
}

.sr-icon-sport-2:before {
  content: "\F008";
}

.sr-icon-sport-4:before {
  content: "\F081";
}

.sr-icon-sport-5:before {
  content: "\F013";
}

.sr-icon-sport-6:before {
  content: "\F028";
}

.sr-icon-sport-12:before {
  content: "\F052";
}

.sr-icon-sport-20:before {
  content: "\F019";
}

.sr-icon-sport-22:before {
  content: "\F115";
}

.sr-icon-sport-23:before {
  content: "\F015";
}

.sr-icon-sport-31:before {
  content: "\F016";
}

.sr-icon-sport-34:before {
  content: "\F014";
}

.sr-icon-dart:before {
  content: "\F113";
}

.sr-scoreboard-icon-surface:before {
  content: "\F011";
}

.sr-lmts-icon-substitution:before {
  content: "\F046";
}

.sr-lmts-icon-timeout:before {
  content: "\F000";
}

.sr-lmts-icon-heatmap-switch:before {
  content: "\F051";
}

.sr-lmts-icon-card:before {
  content: "\F027";
}

.sr-lmts-icon-man:before {
  content: "\F105";
}

.sr-lmts-icon-man-sport:before {
  content: "\F101";
}

.sr-lmts-sid-12 .sr-lmts-icon-man-sport:before {
  content: "\F102";
}

.sr-lmts-sid-2 .sr-lmts-icon-man-sport:before {
  content: "\F104";
}

.sr-lmts-sid-6 .sr-lmts-icon-man-sport:before {
  content: "\F103";
}

.sr-lmts-icon-xxx:before {
  content: "\F123";
}

.sr-icon-status-abandoned:before {
  content: "\F082";
}

.scoreboard {
  position: relative;
  top: 142px;
  left: 50px;
  width: 568px;
  margin: 0 auto;
}

.scoreboard-left {
  width: 206px;
  min-height: 175px;
  margin-right: 15px;
}

.scoreboard-center {
  z-index: 5;
  width: 250px;
  min-height: 175px;
  margin: -35px 0 0 0;
}

.scoreboard-right {
  width: 83px;
  min-height: 175px;
  margin-left: 14px;
}

.scoreboard-left-row {
  margin-bottom: 11px;
}

.stat-title {
  font-size: 9px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  height: 18px;
  padding: 0 30px 0 30px;
  color: #fff;
}

.stat-title-home-number {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 18px;
  padding: 0 2px;
}

.stat-title-away-number {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 18px;
  padding: 0 2px;
  text-align: right;
}

.stat-bars {
  position: relative;
  border: 1px solid #091925;
}

.home-stat-bar {
  float: left;
  height: 7px;
  background: #297145;
}

.away-stat-bar {
  float: left;
  height: 7px;
  background: #fcb42b;
}

.away-stat-bar-animation {
  width: 85px;
  height: 7px;
  animation-name: stat-bar-animation;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-image: url("/assets/img/@1x/parallax/sportsbook/stat-bar-animation.png");
  background-repeat: repeat-x;
  background-position: 0 0;
}

@keyframes stat-bar-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 110px 0;
  }
}

.stat-bar-sep {
  position: absolute;
  top: -5px;
  left: 35%;
  width: 2px;
  height: 12px;
  margin-left: -1px;
  background-color: rgba(239, 239, 239, 0.56);
}

.scoreboard-score {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  height: auto;
  margin-bottom: 13px;
  padding: 0 3px;
  text-align: center;
  text-transform: uppercase;
  opacity: .5;
  color: #fefefe;
}

.scoreboard-time-bar {
  position: relative;
  height: 20px;
  margin-right: 22px;
  margin-left: 18px;
  background: #4c4c4c;
  box-shadow: 0 0 4px 1px #000;
}

.scoreboard-time-bar-inner {
  width: 94%;
  height: 100%;
  background: linear-gradient(to bottom, #467649 0, #1a311c 100%);
}

.scoreboard-time-bar-ht {
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  top: -12px;
  left: 50%;
  width: 23px;
  height: 32px;
  margin: 0 0 0 -9px;
  color: #a7a7a7;
  border: 1px solid #333;
  background: linear-gradient(to bottom, #656565 0%, #4a4a4a 100%);
}

.scoreboard-time-bar-ht-inner {
  line-height: 28px;
  height: 28px;
  margin: 1px;
  text-align: center;
  border-top: 1px solid #4d4d4d;
  background: linear-gradient(to bottom, #565656 0%, #3f3f3f 100%);
  text-shadow: none;
}

.scoreboard-time-bar-stamp {
  position: absolute;
  bottom: 0;
  width: 2px;
  border-right: 1px solid #133719;
  border-left: 1px solid #3e6c41;
}

.scoreboard-time-ball {
  position: absolute;
  top: -7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("/assets/img/@2x/parallax/sportsbook/ball.png") no-repeat center center;
  background-size: contain;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.5);
}

.scoreboard-incident-info {
  font-weight: 400;
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 10px;
  display: none;
  padding: 5px 10px 5px 39px;
  text-transform: uppercase;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: none;
}

.scoreboard-incident-minute {
  font-size: 15px;
  line-height: 29px;
  width: 29px;
  height: 29px;
  margin: 0 0 0 -34px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}

.home-incident .scoreboard-incident-minute {
  background: #7c0008;
}

.away-incident .scoreboard-incident-minute {
  background: #0550a5;
}

.scoreboard-incident-type {
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  color: #080808;
}

.scoreboard-incident-team {
  font-size: 11px;
  line-height: 12px;
  white-space: nowrap;
  color: #7b7b7b;
}

*:hover > .scoreboard-incident-info {
  display: block;
}

.scoreboard-field {
  position: relative;
  overflow: hidden;
  margin: 9px 0 0 0;
}

.scoreboard-field .sf-field {
  display: block;
}

.scoreboard-field .sf-incident {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scoreboard-field .sf-incident-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.scoreboard-field .sf-incident-title {
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  top: 60px;
  left: 90px;
  text-transform: uppercase;
  color: #fff;
}

.scoreboard-troubles-item {
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  text-transform: uppercase;
}

.scoreboard-troubles-item-title {
  text-align: center;
}

.scoreboard-troubles-item.g-left {
  text-align: left;
}

.scoreboard-troubles-item.g-right {
  text-align: right;
}

.scoreboard-yellow-card {
  position: absolute;
  top: -6px;
  width: 13px;
  height: 17px;
  border-radius: 2px;
  background: #e3b026;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.5);
}

.scoreboard-red-card {
  position: absolute;
  top: -6px;
  width: 13px;
  height: 17px;
  border-radius: 2px;
  background: #9f1a11;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.5);
}

@font-face {
  font-family: LMTS-icons;
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/LMT/LMT.eot");
  src: url("../fonts/LMT/LMT.eot?#iefix") format("embedded-opentype"), url("../fonts/LMT/LMT.woff2") format("woff2"), url("../fonts/LMT/LMT.woff") format("woff"), url("../fonts/LMT/LMT.ttf") format("truetype");
}

/* circle */
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  clip: rect(0em, 0.5em, 1em, 0em);
  width: .60em;
  height: .60em;
  transform: rotate(0deg);
  border: .20em solid #307bbb;
  border-radius: 50%;
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  transform: rotate(180deg);
}

.c100 {
  font-size: 120px;
  position: relative;
  float: left;
  width: 1em;
  height: 1em;
  margin: 0 .1em .1em 0;
  border-radius: 50%;
  background-color: #ccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 240px;
}

.c100.small {
  font-size: 80px;
}

.c100 > span {
  font-size: .2em;
  line-height: 5em;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  width: 5em;
  transition-timing-function: ease-out;
  transition-duration: .2s;
  transition-property: all;
  text-align: center;
  white-space: nowrap;
  color: #ccc;
}

.c100:after {
  position: absolute;
  top: .20em;
  left: .20em;
  display: block;
  width: .60em;
  height: .60em;
  content: " ";
  transition-timing-function: ease-in;
  transition-duration: .2s;
  transition-property: all;
  border-radius: 50%;
  background-color: #f5f5f5;
}

.c100 .slice {
  position: absolute;
  clip: rect(0em, 1em, 1em, 0.5em);
  width: 1em;
  height: 1em;
}

.c100.p1 .bar {
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  transform: rotate(18deg);
}

.c100.p6 .bar {
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  transform: rotate(36deg);
}

.c100.p11 .bar {
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  transform: rotate(54deg);
}

.c100.p16 .bar {
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  transform: rotate(72deg);
}

.c100.p21 .bar {
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  transform: rotate(90deg);
}

.c100.p26 .bar {
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  transform: rotate(108deg);
}

.c100.p31 .bar {
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  transform: rotate(126deg);
}

.c100.p36 .bar {
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  transform: rotate(144deg);
}

.c100.p41 .bar {
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  transform: rotate(162deg);
}

.c100.p46 .bar {
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  transform: rotate(169.2deg);
}

.c100.p48 .bar {
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  transform: rotate(180deg);
}

.c100.p51 .bar {
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  transform: rotate(198deg);
}

.c100.p56 .bar {
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  transform: rotate(216deg);
}

.c100.p61 .bar {
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  transform: rotate(234deg);
}

.c100.p66 .bar {
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  transform: rotate(252deg);
}

.c100.p71 .bar {
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  transform: rotate(270deg);
}

.c100.p76 .bar {
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  transform: rotate(288deg);
}

.c100.p81 .bar {
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  transform: rotate(306deg);
}

.c100.p86 .bar {
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  transform: rotate(324deg);
}

.c100.p91 .bar {
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  transform: rotate(342deg);
}

.c100.p96 .bar {
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100:hover > span {
  font-size: .3em;
  line-height: 3.33em;
  width: 3.33em;
  color: #307bbb;
}

.c100:hover:after {
  top: .04em;
  left: .04em;
  width: .92em;
  height: .92em;
}

.c100.dark {
  background-color: #777;
}

.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}

.c100.dark > span {
  color: #777;
}

.c100.dark:after {
  background-color: #666;
}

.c100.dark:hover > span {
  color: #c6ff00;
}

.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}

.c100.green:hover > span {
  color: #4db53c;
}

.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}

.c100.green.dark:hover > span {
  color: #5fd400;
}

.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}

.c100.orange:hover > span {
  color: #dd9d22;
}

.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}

.c100.orange.dark:hover > span {
  color: #e08833;
}

/* financials*/
.stocks-marquee_container {
  position: absolute;
  z-index: 101;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  width: 1450px;
  height: 39px;
  margin: 0 0 0 -725px;
}

.stocks-marquee {
  width: 100%;
  height: 100%;
  background: url("/assets/img/@1x/parallax/financials/stocks-marquee-bg.png") repeat-x top left;
}

.stocks-marquee-shadow-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background: url("/assets/img/@1x/parallax/financials/stocks-marquee-shadow-right.png") repeat-y left top;
}

.stocks-marquee-shadow-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: url("/assets/img/@1x/parallax/financials/stocks-marquee-shadow-left.png") repeat-y right top;
}

.stock_item {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 19px;
  float: left;
  height: 39px;
  padding: 0 12px;
  background: url("/assets/img/@1x/parallax/financials/stock_item-sep.png") no-repeat 0 4px;
  text-shadow: none;
}

.stock_item_name {
  color: #000;
}

.stock_item_value {
  color: #c98244;
}

.stock_item_value.value_up {
  color: #fff;
}

.stock_item_value.value_down {
  color: #c98244;
}

.stock_item_value span {
  padding: 0 0 0 5px;
  color: #899197;
}

.stock_item_value span .fa {
  font-size: 1.2em;
  margin: 0 5px 0 0;
}

.stock_item_value.value_up span {
  color: #49963f;
}

.stock_item_value.value_down span {
  color: #c43e3e;
}

.bonds-marquee_container {
  position: absolute;
  top: 123px;
  left: 50%;
  overflow: hidden;
  width: 1200px;
  height: 21px;
  margin: 0 0 0 -600px;
}

.bonds-marquee {
  width: 100%;
  height: 100%;
  background: url("/assets/img/@1x/parallax/financials/bonds-marquee-bg.png") repeat-x top left;
}

.bond_item {
  font-size: 1.1em;
  font-weight: 400;
  line-height: 21px;
  float: left;
  height: 21px;
  padding: 0 12px;
  text-transform: uppercase;
  color: #96a0a1;
  text-shadow: none;
}

.real-time-exchange {
  position: absolute;
  top: 181px;
  left: 50%;
  margin: 0 0 0 -305px;
  text-shadow: none;
}

.real-time-exchange-title {
  font-size: 1.4em;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  padding: 0 8px;
  text-transform: uppercase;
  color: #dbe3e7;
  background: rgba(13, 36, 43, 0.55);
}

.real-time-exchange-item {
  font-weight: 400;
  width: 91px;
  height: 120px;
  margin: 0 0 0 3px;
  padding: 0 0 0 8px;
  color: #fff;
}

.real-time-exchange-item:first-child {
  margin-left: 0;
}

.real-time-exchange-item.value_up {
  background: url("/assets/img/@1x/parallax/financials/real-time-exchange-up.png") no-repeat top left;
}

.real-time-exchange-item.value_down {
  background: url("/assets/img/@1x/parallax/financials/real-time-exchange-down.png") no-repeat top left;
}

.real-time-exchange-item-title {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 22px;
  opacity: .64;
}

.real-time-exchange-item-title span {
  font-weight: 400;
}

.real-time-exchange-item-value {
  font-size: 1.6em;
}

.real-time-exchange-item-change_v {
  font-size: 1.6em;
}

.real-time-exchange-item-change_v span {
  opacity: .42;
}

.real-time-exchange-item-change_v .fa {
  font-size: 1.6em;
  line-height: 16px;
  position: relative;
  top: 2px;
  margin: 0 2px 0 0;
}

.real-time-exchange-item-change_v .fa-caret-down {
  color: #9e2e2f;
}

.real-time-exchange-item-change_v .fa-caret-up {
  color: #3fce50;
}

.real-time-exchange-item-change_p {
  font-size: 1.6em;
  line-height: 16px;
  padding: 0 0 0 21px;
}

.futures_container {
  position: absolute;
  top: 148px;
  left: 50%;
  margin: 0 0 0 335px;
  text-shadow: none;
}

.futures_graph_container {
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 119px;
  background: url("/assets/img/@1x/parallax/financials/futures_graph_container_bg.png") no-repeat top left;
}

.futures_table_container {
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 104px;
  padding: 0 0 0 0;
  background: url("/assets/img/@1x/parallax/financials/futures_table_container_bg.png") no-repeat top left;
}

.futures_graph_title {
  font-size: 1em;
  font-weight: 400;
  line-height: 12px;
  width: 118px;
  padding: 4px 0 0 0;
  text-align: right;
  text-transform: uppercase;
  opacity: .67;
  color: #fcfcfc;
}

.futures_graph_title span {
  font-size: .8em;
}

.futures_graph-item {
  font-size: 1em;
  font-weight: 200;
  line-height: 12px;
  width: 118px;
  text-align: right;
  color: #fcfcfc;
}

.futures_graph-item-change_v,
.futures_graph-item-change_p_box {
  font-size: .8em;
  font-weight: 700;
}

.futures_graph-item-change_v span,
.futures_graph-item-value,
.futures_graph-item-change_p_box {
  opacity: .67;
}

.futures_graph-item.value_down .futures_graph-item-change_v,
.futures_graph-item.value_down .futures_graph-item-change_p_box {
  color: #d73343;
}

.futures_graph-item.value_up .futures_graph-item-change_v,
.futures_graph-item.value_up .futures_graph-item-change_p_box {
  color: #3fce50;
}

.futures_graph-item-change_v .fa {
  margin: 0 0 0 5px;
}

.futures_graph-marquee {
  position: absolute;
  top: 58px;
  left: 26px;
  overflow: hidden;
  width: 141px;
  height: 48px;
}

.futures_graph-scale {
  font-size: 8px;
  position: absolute;
  top: 20px;
  right: 23px;
  opacity: .67;
  color: #fcfcfc;
}

.futures_graph-day {
  font-size: 8px;
  position: absolute;
  opacity: .67;
  color: #fcfcfc;
}

.futures_graph-day.monday {
  right: 30px;
  bottom: 2px;
}

.futures_graph-day.tuesday {
  bottom: 2px;
  left: 27px;
}

.futures_graph-day.wednesday {
  bottom: 2px;
  left: 44px;
}

.futures_graph-day.thursday {
  bottom: 2px;
  left: 75px;
}

.futures_graph-day.friday {
  bottom: 2px;
  left: 106px;
}

.futures_graph-value {
  font-size: 8px;
  position: absolute;
  opacity: .53;
  color: #fcfcfc;
}

.futures_graph-value.first {
  top: 41px;
  left: 7px;
}

.futures_graph-value.second {
  top: 59px;
  left: 7px;
}

.futures_graph-value.third {
  top: 79px;
  left: 7px;
}

.futures_graph-value.fourth {
  top: 100px;
  left: 7px;
}

.futures_table_title {
  font-size: .9em;
  font-weight: 400;
  line-height: 10px;
  padding: 4px 11px 0 10px;
  opacity: .67;
  color: #fcfcfc;
}

.futures_table_header {
  font-size: .8em;
  font-weight: 400;
  line-height: 8px;
  padding: 2px 11px 0 10px;
  text-align: center;
  opacity: .67;
  color: #75c2cb;
}

.futures_table_header > div {
  width: 30%;
}

.futures_table_body {
  padding: 2px 0 0 0;
}

.futures_table-item-container {
  padding: 1px 0 4px 10px;
  border-right: 1px solid #111f22;
  border-bottom: 1px solid #111f22;
  border-left: 1px solid #173e44;
  background: #081618;
}

.futures_table-item-title {
  font-size: .9em;
  font-weight: 400;
  line-height: 12px;
  padding: 0 11px 0 0;
  text-transform: uppercase;
  opacity: .67;
  color: #ddc18a;
}

.futures_table-item-title span {
  font-size: .8em;
  font-weight: 200;
  color: #fcfcfc;
}

.futures_table-item {
  font-size: .9em;
  font-weight: 200;
  line-height: 8px;
  padding: 0 11px 0 0;
  text-align: center;
  color: #fcfcfc;
}

.futures_table-item-value,
.futures_table-item-change_v,
.futures_table-item-change_p_box {
  float: right;
  width: 30%;
  opacity: .67;
}

.futures_table-item .fa {
  font-size: 1.3em;
  line-height: 8px;
  margin: 0 -3px 0 0;
}

.futures_table-item.value_down .fa {
  color: #d73343;
}

.futures_table-item.value_up .fa {
  color: #3fce50;
}

/* end financials*/
